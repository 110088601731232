
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$premiumreportform-web-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$premiumreportform-web-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$premiumreportform-web-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$premiumreportform-web-theme: mat.m2-define-light-theme((
  color: (
    primary: $premiumreportform-web-primary,
    accent: $premiumreportform-web-accent,
    warn: $premiumreportform-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($premiumreportform-web-theme);

@charset "UTF-8";

@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Thin.woff") format("woff"),
    url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Roboto-Bold", sans-serif;
  src: url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Roboto-Regular", sans-serif;
  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Black.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Black.woff") format("woff"),
    url("./assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Roboto-Light",sans-serif;
  src: url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Roboto-Medium", sans-serif;
  src: url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
/** Material Icon font **/
@font-face {
  font-family: "Material Icons", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  // src: url(./assets/fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: url("./assets/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("./assets/fonts/MaterialIcons-Regular.woff") format("woff"),
    url("./assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

.igx-grid__td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-navbar {
  background-color: #70a9d0 !important;
}
app-igxgrid1 {
  width: 100%;
}

.table tr:nth-child(even) {
  background-color: #F8F8F8;
}

.table th.sorted {
  color: #004EAA; /* Set the color for sorted columns in descending order */
}

.interactive-links {
  color: #004EAA;
  a {
    color: #004EAA;
  }
}
igx-grid-header {
  background-color: #6ea3cc;
  background-repeat: repeat-x;
  background-image: url(../src/assets/igg_header.gif);

  border-top: 1px solid #a0c3de;
  border-right: 1px solid #5593c3;
  border-bottom: 1px solid #5593c3;
  border-left: 1px solid #a0c3de;
}
// overriding the default column header height of igx-grid
.igx-grid__th-title {
  //line-height: 8 !important;
  opacity: 1 !important;
}
.igx-grid-th__title {
  //line-height: 8 !important;
  opacity: 1 !important;
}


igx-grid-header span span {
  font-size: 12px !important;
  padding: 3px 0 3px 8px !important;
  overflow: hidden !important;
  text-align: left !important;
  color: White !important;
  font-family: Verdana, Arial, Helvetica,sans-serif !important;

  // // // white-space: nowrap;
  // // // text-overflow: ellipsis;
  // // // overflow: hidden;
  // // font-size: 12px;
  // // font-weight: bolder;
  // // font-family: Arial, Helvetica, sans-serif;
  // word-spacing: 100vw;
  // margin: auto;
  // white-space: pre-line !important;
}

.lblCellName {
  font-family: Verdana, Arial, Helvetica,sans-serif;
  font-size: 11px;
  line-height: 16px;
  margin: 0px;
  padding: 5px;
  vertical-align: top;
  width: 100px;
}

// .lblCellFormula {
//   font-family: Verdana, Arial, Helvetica;
//   font-size: 11px;
//   line-height: 16px;
//   margin: 0px;
//   padding: 5px;
//   vertical-align: top;
// }

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.table-material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  vertical-align: middle;
}
.noscroll {
  overflow: hidden;
}

.pagetitle {
  font-family: "Roboto",sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #3F3F3F;
  text-align: left;
}
.pagetitleSecondLayer {
  font-family: "Roboto-Light",sans-serif;
  font-size: 20px;
  color: #333;
  text-align: left;
}
.titleLight {
  font-family: "Roboto-Light",sans-serif;
  font-size: 14px;
  color: #76797b;
  text-align: left;
}
.titleBold {
  font-family: "Roboto",sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #76797b;
  text-align: left;
}
.error {
  font-size: 14px;
  color: red;
}
.note {
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #76797B;
}
.noteTitle {
  font-weight: 700;
}
.pagetitleSecondLayerBold {
  font-family: "Roboto",sans-serif;
  font-size: 18px;
  color: var(--Grey-Text-Color, #3F3F3F);
  font-weight: bold;
  text-align: left;
  line-height: 28px;
}
.pagetitlesubtext {
  padding-top: 2px;
  font-family: "Roboto-Regular",sans-serif;
  font-size: 14px;
  text-align: left;
}

.dropdown-label{
  font-size: 13px;
 padding: 10px 5px 1px 5px;

  z-index: 1;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #76797b;
  pointer-events: none;
}


.lib-text-field[required] {
  .lib-placeholder::after {
    content: " (required)";
  }

  input:focus + .lib-placeholder::before,
  input:not(:placeholder-shown) + .lib-placeholder::before {
    content: "* ";
  }
  input:focus + .lib-placeholder::after,
  input:not(:placeholder-shown) + .lib-placeholder::after {
    content: "";
  }
}

.lib-text-field {
  margin: 20px 0;
  position: relative;
  display: block;
  .lib-placeholder {
    padding: 5px 5px 1px 5px;
    font-size: 16px;
    z-index: 9;
    background: none;
    font-family: "Roboto-Regular", "Roboto",sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #76797b;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
  }

  input {
    padding: 10px 5px 1px 5px;
    width: calc(100% - 10px);
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #878787;
    position: relative;
    background: none;
    z-index: 10;
    line-height: 1.6;
    font-size: 16px;
    font-family: "Roboto-Regular", "Roboto",sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }

  input:focus {
    outline-width: 0;
    box-shadow: 0 2px 0 #00a9e0;
    border-color: #00a9e0;
  }

  input:focus + .lib-placeholder,
  input:not(:placeholder-shown) + .lib-placeholder {
    opacity: 1;
    transform: translateY(-17px) translateX(-5px);
    font-size: 13px;
  }

  /* For IE Browsers*/
  input:focus + .lib-placeholder,
  input:not(:-ms-input-placeholder) + .lib-placeholder {
    opacity: 1;
    transform: translateY(-17px) translateX(-5px);
    font-size: 13px;
  }
}

/* Table css */

.table td {
  border-bottom: 1px solid #d3d4d5 !important;
  border-top: 0px !important;
  padding: 6px !important;
  font-family: "Roboto-Regular",sans-serif;
  font-size: 12px;
  text-align: left;
  color: #333333;
  height: 35px;
  vertical-align: middle;
}

.table thead th {
  border-bottom: 4px solid #ddebf0 !important;
  font-size: 14px !important;
  font-family: "Roboto-Bold" ,"Roboto",sans-serif !important;
  font-weight: bold;
  color: #333333;
}
.table-hover tbody tr:hover{
    background-color: #eaf2fc;
}

.symbol-data *{
  font-size: 12px !important;
}

.table th {
  padding: 10px 10px 10px 5px !important;
  border-top: 0px !important;
}
.locked {
  color: #d10025;
  cursor: pointer;
  text-decoration: underline;
  .table-material-icons{
    color: #d10025;
  }
}
.lockedCust {
  color: #d10025;
  .table-material-icons{
    color: #d10025;
  }
}

.verified {
  color: #2f9033;
  cursor: pointer;
  text-decoration: underline;
  .table-material-icons{
    color: #2f9033;
  }
}
.verifiedCust {
  color: #2f9033;
  .table-material-icons{
    color: #2f9033;
  }
}

.delete_disabled {
  color: gray;
}
.delete {
  color: #004EAA;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
}
.export {
  color: #004EAA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
}
.edit {
  color: #004EAA;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
}
.table_no_data_found {
  font-size: 13px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  border-bottom: 1px solid #ccc;
  color: #004EAA;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
}
.optionHeader {
  font-size: 16px;

  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #76797b;

  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.toast-body {
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-size: 14px;
  color: #00b16a;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d4edda;
  border: 1px solid;
  border-radius: 2px;
  border-color: #78c78a;
  font-weight: bold;
}

.PageLevelError {
  width: 100%;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-size: 14px;
  color: #da291c;
  background: #f8d4d2;
  border: 1px solid #f0a9a4;
  border-radius: 2px;
  text-align: left;
  padding: 10px;
  .table-material-icons{
  color: #da291c;
  }
}

.PageLevelWarning {
  width: 100%;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-size: 14px;
  color: #27BC7E;
  background: #D4EDDA;
  border: 1px solid #D4EDDA;
  border-radius: 2px;
  text-align: left;
  font-weight: bold;
  padding: 10px;
  .table-material-icons{
    color:#27BC7E;
  }
}

.information {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #CCE1ED;
  border: 1px solid;
  border-color: #1F78B4;
  padding: 15px;
  border-radius: 2px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-size: 13px;
  color: #76797B;
  line-height: 3px;
}
.messagetitle{
  font-family: "Roboto-Medium", "Roboto",sans-serif;
  color: #1F78B4;
  line-height: 28px;
  font-size: 15px;
}
.inputMsg {
  background: #f8d4d2;
  font-family: "Roboto-Light", "Roboto",sans-serif;
  font-size: 12px;
  color: #da291c;
  padding: 5px;
  .table-material-icons{
    color: #da291c;
  }
}
.table-material-icons {
  color: #004EAA;
  font-size: 18px;
}


.table-hover tbody tr td:hover {
  .delete{
  .table-material-icons{
    color: #00a9e0;
    }
  }
  .edit{
    .table-material-icons{
      color: #00a9e0;
      }
    }
}

.table-hover thead tr th:hover{
  .table-material-icons{
    color: #00a9e0;
  }
}
.modal-backdrop.show{
  opacity: 0;
  z-index: 1050 !important;
}

.myCustomModalClass .modal-dialog {
  max-width: 965px;
}

.smallModal .modal-dialog {
  max-width: 250px;
}
.finalSubmissionModal .modal-dialog {
  max-width: 610px;
}
.systemfailcontainer {
  background-color: #e5e5e6;
  border-top: 2px solid;
  border-color: #54585a;
  padding: 15px;
  border-radius: 0px 0px 2px 2px;
  font-family: "Roboto-Regular", "Roboto",sans-serif;
  font-size: 13px;
  color: #333333;
  line-height: 3px;

  .systemfailtitle {
    font-family: "Roboto-Bold", "Roboto",sans-serif;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    font-size: 15px;
  }
  .systemfailbody {
    font-family: "Roboto-Medium", "Roboto",sans-serif;
    color: #76797b;
    line-height: 28px;
    font-size: 13px;
  }
  .supportcontact {
    font-family: "Roboto-Medium", "Roboto",sans-serif;
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .contactbody{
    font-family: "Roboto-Regular", "Roboto",sans-serif;
    color: #76797b;
    font-size: 13px;
    span{
      color: #00a9e0;
      text-decoration: underline;
    }
  }

}

.interactive-links:hover .table-material-icons {
  color: #00a9e0 !important;
}

.tooltip-class .tooltip-inner {
  background-color: #00a9e0;
  font-family: "Roboto-Medium", "Roboto",sans-serif;
  font-size: 13px;
}

.tooltip-class .arrow::before {
  border-bottom-color: #00a9e0;
}

.modal-content{
  border-color: #ffffff;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
